@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-selector {
  @apply !bg-gray-modern-100 !border-none !ring-0 focus:!ring-[0.5px];
}

.ant-select-multiple .ant-select-selector {
  @apply px-2 py-2;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
  @apply bg-active text-white text-[14px];
}

.ant-select-multiple
  .ant-select-selection-overflow
  .ant-select-selection-item-remove {
  @apply bg-active text-white text-[14px] hover:text-blue-dark-50;
}

.ant-select .ant-select-arrow {
  @apply text-primary;
}

.ant-select-dropdown .ant-select-item-option-content {
  @apply text-primary text-[14px];
}

.ant-picker .ant-picker-outlined {
  @apply bg-gray-modern-100 rounded-[8px] focus:bg-gray-modern-100;
}

.ant-picker-outlined:focus-within {
  @apply bg-gray-modern-100 !border-active !ring-active ring-[0.5px];
}

.ant-picker-outlined:hover {
  @apply bg-gray-modern-100 !border-active !ring-active ring-[0.5px];
}

.ant-select-multiple .ant-select-selector {
  @apply bg-gray-modern-100 !rounded-[8px] focus:bg-gray-modern-100;
}

.ant-select .ant-select-outlined {
  @apply bg-gray-modern-100 !rounded-[8px] focus:bg-gray-modern-100;
}

.ant-select-single .ant-select-selector {
  @apply bg-gray-modern-100 !rounded-[8px] focus:bg-gray-modern-100;
}

.ant-select-outlined:focus-within {
  @apply bg-gray-modern-100 !border-active !ring-active ring-[0.5px] rounded-[8px];
}

.ant-select-outlined:hover {
  @apply bg-gray-modern-100 !border-active !ring-active ring-[0.5px] rounded-[8px];
}
